@import '../../../../scss/theme-bootstrap';

.hero-block {
  text-align: $ldirection;
  &__content,
  &__content-wrapper,
  &__content-over-media,
  &__media-wrapper {
    display: flex;
    flex-direction: column;
  }
  &__content-wrapper {
    height: auto;
  }
  &__wrapper {
    &--border {
      border: $color-cream-stroke 1px solid;
    }
    &.media-align {
      &-right-mob,
      &-left-mob,
      &-bottom-mob {
        display: flex;
      }
      &-right-mob {
        flex-direction: row-reverse;
      }
      &-bottom-mob {
        flex-direction: column-reverse;
      }
      @include breakpoint($landscape-up) {
        &-right-pc,
        &-left-pc,
        &-bottom-pc {
          display: flex;
        }
        &-right-pc {
          flex-direction: row-reverse;
        }
        &-bottom-pc {
          flex-direction: column-reverse;
        }
      }
    }
  }
  &__media {
    display: block;
    width: 100%;
    height: auto;
    &--maxed {
      overflow: hidden;
    }
    &--native {
      .mantle-media-asset {
        img,
        video {
          width: auto;
        }
      }
      &.native-align--left {
        .mantle-media-asset {
          img,
          video {
            float: #{$ldirection};
          }
        }
      }
      &.native-align--right {
        .mantle-media-asset {
          img,
          video {
            float: #{$rdirection};
          }
        }
      }
    }
  }
  &__media-wrapper {
    position: relative;
    text-align: center;
    .content-block__link {
      a:not(.button-small):not(.button-small--border-radius):hover {
        box-shadow: 0 1px 0 0;
      }
    }
    &.media_text--boxed {
      display: grid;
      grid: repeat(3, auto) / 1fr;
      gap: 0;
      row-gap: 10px;
      @include breakpoint($landscape-up) {
        gap: 10px;
        grid: repeat(3, auto) / 1fr 380px 1fr;
        .basic-carousel__slide & {
          grid: repeat(3, auto) / 1fr minmax(0, 380px) 1fr;
        }
      }
      .hero-block__media {
        width: 100%;
        height: 100%;
        object-fit: cover;
        grid-area: 1 / 1 / -1 / -1;
        img {
          width: 100%;
          height: auto;
        }
      }
      .hero-block__content-over-media {
        &.media_content--card {
          grid-column: 1;
          padding: 30px 30px 24px;
          position: relative;
          max-width: calc(100% - 40px);
          .basic-carousel__slide & {
            min-width: 0;
            width: calc(100% - 40px);
            @include breakpoint($landscape-up) {
              grid-column: 1 / -1;
              padding: 32px 20px;
              max-width: 380px;
            }
            @include breakpoint($xlarge-up) {
              padding: 32px 40px;
            }
            @include breakpoint($extralarge-up) {
              max-width: 380px;
            }          
          }
          @include breakpoint($landscape-up) {
            padding: 32px 40px;
            margin: 44px;
            width: 380px;
            min-width: 380px;            
          }
          &_border_rounded {
            border-radius: 10px;
          }
          &_mobile_row--1 {
            grid-row: 1;
            align-self: flex-start;
            margin: 20px auto;
          }
          &_mobile_row--2 {
            grid-row: 2;
            align-self: center;
            margin: 0 auto;
          }
          &_mobile_row--3 {
            grid-row: 3;
            align-self: flex-end;
            margin: 20px auto;
          }
          &_col--1 {
            @include breakpoint($landscape-up) {
              grid-column: 1;
              margin-#{$ldirection}: 44px;
              .basic-carousel__slide & {
                margin-inline-start: 20px;
              }
            }
          }
          &_col--2 {
            @include breakpoint($landscape-up) {
              align-self: center;
              grid-column: 2;
              margin: auto;
              margin-#{$ldirection}: auto;
              margin-#{$rdirection}: auto;
            }
          }
          &_col--3 {
            @include breakpoint($landscape-up) {
              grid-column: 3;
              justify-self: flex-end;
              margin-#{$rdirection}: 44px;
              .basic-carousel__slide & {
                margin-inline-end: 20px;
              }
            }
          }
          &_row--1 {
            @include breakpoint($landscape-up) {
              align-self: flex-start;
              grid-row: 1;
              margin-top: 44px;
            }
          }
          &_row--2 {
            @include breakpoint($landscape-up) {
              grid-row: 2;
              align-self: center;
            }
          }
          &_row--3 {
            @include breakpoint($landscape-up) {
              align-self: flex-end;
              grid-row: 3;
              margin-bottom: 44px;
            }
          }
          &_cta_alignment--start {
            .content-block__link-wrapper {
              justify-content: flex-start;
            }
          }
          &_cta_alignment--center {
            .content-block__link-wrapper {
              justify-content: center;
            }
          }
          &_cta_alignment--end {
            .content-block__link-wrapper {
              justify-content: flex-end;
            }
          }
          .content-over-media__text-wrapper {
            width: 100%;
            margin-bottom: 0;
            &:first-of-type {
              .custom-text {
                line-height: get-line-height(24px, 28px);
              }
            }
            p {
              margin-bottom: 0;
              line-height: get-line-height(16px, 20px);
            }
          }
          .content-block__link-wrapper {
            width: 100%;
            margin-top: 15px;
          }
        }
      }
    }
  }
  &__content-over-media {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    .hero-block-no-media & {
      position: relative;
    }
  }
  .content-block__link-wrapper {
    &--row {
      .content-block__link-gutter {
        height: 0;
      }
    }
    &--column {
      .content-block__link-gutter {
        width: 0;
      }
    }
  }
  .content-block__line {
    padding-bottom: 20px;
    &.padding-0 {
      padding: 0;
    }
    &--eyebrow {
      padding-top: 8px;
      @include breakpoint($landscape-up) {
        padding-top: 18px;
      }
    }
  }
  // Account for helper width classes - hero needs this customized, because, Hero.
  &.container-max-width--padded.mobile-media-full {
    @include breakpoint($landscape-down) {
      padding-#{$ldirection}: 0;
      padding-#{$rdirection}: 0;
    }
    .hero-block__content-wrapper {
      @include breakpoint($landscape-down) {
        padding-#{$ldirection}: $container-pad-small;
        padding-#{$rdirection}: $container-pad-small;
      }
    }
    .mantle-media-asset {
      @include breakpoint($landscape-down) {
        margin-#{$ldirection}: 0;
        margin-#{$rdirection}: 0;
      }
    }
  }
  .interactive_media_node {
    position: absolute;
    top: 0;
    #{$ldirection}: 0;
    height: 100%;
    z-index: 1;
    width: 100%;
    visibility: visible;
    cursor: none;
    overflow: hidden;
    .interactive-media {
      overflow: hidden;
      &__play {
        cursor: none;
        pointer-events: none;
      }
      &__controls {
        #{$rdirection}: 0 !important;
        bottom: 0 !important;
        @include swap_direction(padding, 40px 32px 40px 40px);
        @include breakpoint($landscape-up) {
          @include swap_direction(padding, 40px 48px 48px 40px);
        }
      }
    }
  }
}
